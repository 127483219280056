.PropertiesListLayout {
  display: flex;
  flex-direction: column;

  .p_hero {
    display: grid;place-items: center;
    position: relative;

    width: 100%;
    $heroHeight: 600px;
    height: $heroHeight;

    @media screen and (max-width: 900px) {
      height: 450px;
    }
    
    img {
      z-index: 1;
      position: absolute;
      top: 0;right: 0;left: 0;;

      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    .ph_overlay {
      z-index: 2;
      position: absolute;
      top: 0;right: 0;left: 0;
      background-color: #000;
      opacity: .54;
      width: 100%;
      height: 100%;
    }

    .ph_cta {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 5;
      height: $heroHeight;

      @media screen and (max-width: 900px) {
        height: 100%;
      }
      
      h1 {
        color: #f5f5f5;
        text-align: center;
        font-family: "Rubik", sans-serif;
        font-size: 80px;
        width: min(80%, 700px);

        @media screen and (max-width: 900px) {
          font-size: 53px;
        }
      }
  
      .ph_btn {
        outline: none;
        border: none;

        font-family: "Roboto", Sans-serif;
        font-size: 1.92em;
        font-weight: 600;
        letter-spacing: 0.6px;
        fill: #ffffff;
        color: #ffffff;
        padding: 15px 25px;
        background-color: #0464EA;
        border-radius: 25px 25px 25px 25px;
        transition: background-color .2s;

        cursor: pointer;

        &:hover {
          transition: background-color .2s;
          background-color: #000;
        }
      }
    }
  }
  
  .p_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 50px 0;

    .p_property {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      width: 336px;
      padding: 10px;
      background-color: #f1f1f1;
      border-radius: 20px;
      
      img {
        width: 100%;height: 285px;
        object-fit: cover;
        border-radius: 20px;
      }

      .p_info {
        display: flex;flex-direction: column;
        gap: 5px;
        width: 100%;
        padding-left: 20px;
        
        > * {
          font-size: 15px;
          
          text-wrap: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }

        h2, b {
          color: black;
          font-weight: 500;
        }

        p {
          font-weight: 400;
          color: #474752;
        }
        
        b {
          font-size: 16px;
        }
      }

      .p_btn {
        margin: auto;
        width: 100%;
        padding: 11px 18px;
        flex-grow: 0;
        background-color: #0464ea;
        border-radius: 15px;
        border: none;
        outline: none;
        font-weight: 600;
        font-size: 15px;
        color: white;
        cursor: pointer;
      }
    }
  }

  .p_paginator {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 50px;
    
    button {
      border: none;
      outline: none;
      border-radius: 20px;
      padding: 10px;
      background: black;
      color: white;
      font-family: "Rubik", sans-serif;
      font-weight: 500;
      cursor: pointer;

      &:disabled {
        opacity: .5;
      }
    }
  }

  .p_loading, .p_empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: #cfebff;

    img {
      width: 300px;
      filter: brightness(2.5);
    }

    h1 {
      width: min(900px, 80%);
      font-size: 3.3rem;
      text-align: center;
    }
    h2 {
      width: min(900px, 80%);
      font-size: 2rem;
      text-align: center;
    }

  }
}