.p_filters {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  height: 80px;
  width: 700px;
  padding: 10px;
  margin: auto;
  margin-top: 50px;

  background-color: #292929;
  color: white;
  border-radius: 25px;

  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: center;

    padding: 20px 10px;
    width: 75%;
    height: unset;
  }

  .SearchBox {
    width: 100%;

    @media screen and (max-width: 900px) {
      margin-bottom: 12px;
    }
  }

  #coordsValue {
    position: absolute;
    font-size: 0px;
    visibility: hidden;
    pointer-events: none;
    top: -100vh;
  }

  .si_error {
    position: absolute;
    bottom: -22px;
    font-size: 16px;
    color: rgb(167, 19, 19);

    @media screen and (max-width: 900px) {
      bottom: -21px;
    }
  }
  
  input {
    padding: 12px 20px;
    border-radius: 15px;
    border: none;
    font-weight: 500;
    width: 100%;
    font-size: 14px;
    color: black;
    box-shadow: 0px 0px 4px #d3d3d3;
    transition: box-shadow 0.3s;

    &::placeholder {
      color: black;
    }
    
    &:focus {
      transition: box-shadow 0.3s;
      outline: none;
      box-shadow: 0px 0px 10px #b3b3b3;
    }

    &.CurrencyInput {
      width: 120px;
      flex-grow: 0;
      flex-shrink: 0;

      @media screen and (max-width: 900px) {
        width: 48%;
      }
    }
  }

  .f_btn {
    flex-shrink: 0;
    width: 100px;
    padding: 12px 20px;
    flex-grow: 0;

    background-color: #0464ea;
    border-radius: 15px;
    border: none;
    outline: none;

    font-weight: 600;
    font-size: 15px;
    color: white;

    cursor: pointer;

    @media screen and (max-width: 900px) {
      width: 100%;
      margin-top: 10px;
    }
  }
}