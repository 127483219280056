.AppLayout {
  display: flex;
  flex-direction: column;
  overflow: auto;

  position: relative;

  height: 100vh;
  width: 100%;  
  
  .Main {
    display: flex;
    flex-direction: column;
    
    width: 100%;
    height: 100%;
    
    overflow: auto;
    
    .mWrap {
      display: flex;
      flex-direction: column;

      height: 100%;
      min-height: 270px;
      width: 100%;
      max-width: 1250px;
      margin: 0 auto;

      @media screen and (max-width: 900px) {
        min-height: unset;
        height: auto;
      }

      .emptyContainer {
        height: 200px;
        display: flex;
        justify-content: center;
        padding-top: 25px;
      }

      .emptyMessageContainer {
        .ph_subTitle {
          width: min(80%, 500px);
          margin: auto;

          line-height: 1.1;
          font-weight: 500;
          font-size: 25px;
          text-align: center;

          @media screen and (max-width: 900px) {
            font-size: 20px;
            margin-top: 50px;
          }
        }
      }
    }
  }
}