.Searcher {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  .s_bgImage {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .s_bgOverlay {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;

    background-color: #000000;
    opacity: 0.56;
  }

  .s_body {
    z-index: 10;
    display: flex;flex-direction: column;
    justify-content: center;align-items: center;
    gap: 20px;
    width: 100%;
    font-family: "Ruubik", sans-serif;

    .s_title {
      color: #f5f5f5;
      font-family: "Rubik", Sans-serif;
      font-size: 3.3rem;
      font-weight: bold;
      letter-spacing: -1px;
      
      @media screen and (max-width: 900px) {
        text-align: center;
        font-size: 40px;
        padding: 0 20px;
      }
    }

    .s_container {
      display: flex;
      width: 90%;
      height: 208px;
      background-color: #FFF3F0;
      border-radius: 25px;
      overflow: hidden;

      @media screen and (max-width: 900px) {
        flex-direction: column;
        justify-content: space-evenly;
        width: min(90%, 360px);
        height: 380px;
      }

      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        cursor: pointer;

        width: 100%;
        // flex-grow: 1;
        text-decoration: none;
        transition: background-color 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        
        @media screen and (max-width: 900px) {
          // padding: 20px 0;  
          gap: 5px;
        }
        
        &:hover {
          transition: background-color 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
          background-color: #fff;
        }
        
        &:nth-child(1) {
          // flex-grow: 2;
        }
        &:nth-child(2) {
          // flex-grow: 2
        }
        &:nth-child(3) {
          // flex-grow: 2;
        }

        h2 {
          font-family: "Rubik", sans-serif;
          font-size: 32px;
          color: #151515;
          
          @media screen and (max-width: 900px) {
            font-size: 30px;
          }
        }

        i {
          font-size: 50px;
          color: #151515;

          @media screen and (max-width: 900px) {
            font-size: 40px;
          }
        }
      }

      i#coordsValue {
        visibility: hidden;
        position: absolute;
        top: -101vh;
        font-size: 0;
        pointer-events: none;
      }

      &.sc_2 {
        align-items: center;
        padding: 0 min(5vw, 100px);
        font-family: "Ruubik", sans-serif;
        gap: 20px;
        
        @media screen and (max-width: 900px) {
          height: auto;
          padding: 30px;
        }

        span {
          cursor: auto;
  
          &:hover {
            background-color: #FFF3F0;
          }
  
          &.s_inputBox {
            position: relative;
            width: 100%;

            
            .SearchBox {
              width: 100%;
              
              @media screen and (max-width: 900px) {
                margin-bottom: 12px;
              }
            }
            
            input {
              padding: 18px 40px;
              border-radius: 20px;
              border: none;
              font-weight: 700;
              font-size: 20px;
              width: 100%;
              
              box-shadow: 0px 0px 4px #d3d3d3;
              transition: box-shadow .3s;

              @media screen and (max-width: 900px) {
                padding: 18px 15px;
              }
              
              &:focus {
                transition: box-shadow .3s;
                outline: none;
                box-shadow: 0px 0px 10px #b3b3b3;
              }
            }

            .si_error {
              position: absolute;
              bottom: -22px;
              font-size: 16px;
              color: rgb(167, 19, 19);
              background-color: #fff0;

              @media screen and (max-width: 900px) {
                bottom: -21px;
              }

            }
          }

          &.s_btnBox {
            display: flex;align-items: center;justify-content: center;
            width: min(35%, 300px);
            
            @media screen and (max-width: 900px) {
              width: min(60%, 300px);  
            }

            .s_btn {
              width: 100%;
              padding: 15px 0;

              outline: none;
              border: none;
              border-radius: 20px;
              background-color: #151515;

              transition: transform .1s;

              cursor: pointer;
              
              color: #f5f5f5;
              font-family: "Ruubik", sans-serif;
              font-size: 24px;
              font-weight: 700;

              
              @media screen and (max-width: 900px) {
                font-size: 20px;
              }
              
              &:hover {                
                transition: transform .1s;
                transform: scale(1.03);
              }
            }
          }
        }
      }


    }
  }
}